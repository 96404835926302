import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import HttpsRedirect from "react-https-redirect";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#fff",
      main: "#fff",
      dark: "#AF1602",
    },
  },
});

ReactDOM.render(
  // <React.StrictMode>
  <MuiThemeProvider theme={theme}>
    <HttpsRedirect>
      <App />
    </HttpsRedirect>
  </MuiThemeProvider>,
  // </React.StrictMode>,
  document.getElementById("root")
);
