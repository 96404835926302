import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
// import Card from "@material-ui/core/Card";
// import CardContent from "@material-ui/core/CardContent";
// import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";
import Skeleton from "@material-ui/lab/Skeleton";
import axios from "axios";
import "./styles.css";
import BottomNav from "../../../Shared/BottomNav";
import Popup from "../../../Shared/Popup";

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: window.localStorage.dialogZielort,
      stations: [],
      tripsData: [],
      uniqueFavs: null,
      unknownStation: true,
      searchField: "",
      selectedName: null,
      code: null,
      display: "hide",
      lat: "",
      lon: "",
      nearbyStation: "",
      popupDisplay: false,
      popup: {
        text: window.location.pathname.includes("/fr")
          ? "Veuillez sélectionner votre arrêt de destination."
          : window.location.pathname.includes("/it")
          ? "Selezioni la sua fermata di destinazione."
          : window.location.pathname.includes("/en")
          ? "Please state the final destination of your journey."
          : "Bitte wählen Sie Ihre Ziel-Haltestelle.",
      },
    };

    this.searchHandler = this.searchHandler.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.handleUser();
    // setInterval(() => {
    //   this.uniqueFavHandler();
    // }, 1000);
  }

  componentDidMount() {
    this.props.backButtonChecker();
    // this.setState({
    //   searchField: this.props.state.endPointName,
    // });
    setTimeout(() => {
      if (this.state.tripsData === null) {
      } else {
        // this.dynamicTextSize();
      }
      this.keyNavigation();
      this.keyEnter();
    }, 500);

    if (this.props.state.screenWidth < 620 && window.localStorage.firstTimeLogin && !window.localStorage.zielortDialog) {
      this.togglePopup();
    }
  }
  componentWillUnmount() {
    this.setState({ code: null, selectedName: null });
  }
  //Toggle popup
  togglePopup = (e) => {
    if (window.localStorage.firstTimeLogin) window.localStorage.setItem("zielortDialog", true);
    if (e) {
      if (e.target === e.currentTarget) this.setState({ popupDisplay: !this.state.popupDisplay });
      return;
    }
    this.setState({ popupDisplay: !this.state.popupDisplay });
  };
  keyEnter = () => {
    document.addEventListener("keydown", function (event) {
      if (event.which === 13) {
        document.getElementsByClassName("selected")[0].click();
      }
    });
  };

  keyNavigation = () => {
    var ul = document.getElementById("list");
    var liSelected;
    var index = -1;
    var next;

    document.addEventListener(
      "keydown",
      function (event) {
        var len = ul.getElementsByTagName("li").length;
        if (event.which === 40) {
          index++;
          //down
          if (liSelected) {
            removeClass(liSelected, "selected");
            next = ul.getElementsByTagName("li")[index];
            if (typeof next !== undefined && index <= len) {
              liSelected = next;
            } else {
              index = 0;
              liSelected = ul.getElementsByTagName("li")[0];
            }
            addClass(liSelected, "selected");
          } else {
            index = 0;
            liSelected = ul.getElementsByTagName("li")[0];
            addClass(liSelected, "selected");
          }
        } else if (event.which === 38) {
          //up
          if (liSelected) {
            removeClass(liSelected, "selected");
            index--;

            next = ul.getElementsByTagName("li")[index];
            if (typeof next !== undefined && index >= 0) {
              liSelected = next;
            } else {
              index = len;
              liSelected = ul.getElementsByTagName("li")[len];
            }
            addClass(liSelected, "selected");
          } else {
            index = 0;
            liSelected = ul.getElementsByTagName("li")[len];
            addClass(liSelected, "selected");
          }
        }
      },
      false
    );

    function removeClass(el, className) {
      if (el.classList) {
        el.classList.remove(className);
      } else {
        el.className = el.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
      }
    }

    function addClass(el, className) {
      if (el.classList) {
        el.classList.add(className);
      } else {
        el.className += " " + className;
      }
    }
  };

  dynamicTextSize = () => {
    let button;
    let that;
    let textLength;

    this.state.uniqueFavs.slice(0, 8).forEach((station) => {
      if (station[0]) {
        // eslint-disable-next-line
        (button = document.getElementById("arrival" + station[1])),
          (that = document.getElementById("arrival" + station[1]).innerHTML),
          // console.log('THAT: ', that),
          (textLength = that.length),
          // console.log('TEXT LENGTH ARRIVALS: ', textLength),

          textLength > 30
            ? button.classList.add("font5")
            : textLength > 20
            ? button.classList.add("font10")
            : textLength > 10
            ? button.classList.add("font14")
            : null;
      }
    });
  };

  getCurrentLocation = () => {
    // console.log('GETTING LOCATION');
    if ("geolocation" in navigator) {
      // console.log("Location Available");
      this.initGeolocation();
    } else {
      console.log("Location Not Available");
    }
  };

  initGeolocation = () => {
    var latitude = "";
    var longitude = "";
    let currentComponent = this;
    navigator.geolocation.getCurrentPosition(function (position) {
      latitude = position.coords.latitude;
      longitude = position.coords.longitude;
      window.localStorage.removeItem("Latitude");
      window.localStorage.removeItem("Longitude");
      // console.log("Latitude is :", latitude);
      // console.log("Longitude is :", longitude);
      window.localStorage.setItem("Latitude", latitude);
      window.localStorage.setItem("Longitude", longitude);
      var proxyUrl = process.env.REACT_APP_PROXY_URL,
        targetUrl = `${process.env.REACT_APP_BASE_URL}/api/Station/SearchStations?`;

      var myHeaders = new Headers();
      myHeaders.append("Authorization", window.localStorage.AccessToken);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        proxyUrl + targetUrl + "longitude=" + window.localStorage.Longitude + "&latitude=" + window.localStorage.Latitude,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          // console.log(result);
          result.Message === "Stations are not found for the provided geo-location!"
            ? currentComponent.setState({ stations: [] })
            : currentComponent.setState({ stations: result });
        })
        .catch((error) => {
          console.log("error", error);
        });
    });
    this.setState({
      lat: window.localStorage.Latitude,
      lon: window.localStorage.Longitude,
    });
  };
  //Capitalize first letter
  toUpperCaseFn(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  searchHandler(event) {
    let keyword = event.target.value;
    keyword = this.toUpperCaseFn(keyword);
    this.setState({
      // unknownStation: true,
      [event.target.name]: keyword,
      selectedName: null,
    });
    if (event.target.value.length > 0) {
      var proxyUrl = process.env.REACT_APP_PROXY_URL,
        targetUrl = `${process.env.REACT_APP_BASE_URL}/api/Station/GetStations`;

      axios
        .get(proxyUrl + targetUrl, {
          headers: { Authorization: window.localStorage.AccessToken },
          params: { query: " " + event.target.value },
        })
        .then((response) => {
          this.setState({ stations: response.data }, () => {
            this.checkStationExistence();
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  handleUser = () => {
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/Journeys/Journeys`;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", window.localStorage.AccessToken);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(proxyUrl + targetUrl, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState(
          {
            tripsData: result,
          },
          () => {
            this.uniqueFavHandler();
          }
        );
      })
      .catch((error) => console.log("error", error));
  };

  uniqueFavHandler = () => {
    if (this.state.tripsData === null) {
      // console.log('NO TRIPS');
      this.setState({
        uniqueFav: null,
      });
    } else {
      const arrivalName = [];
      const arrivalId = [];
      const departureName = [];
      const departureId = [];
      this.state.tripsData.map((station) => arrivalName.push(station.ArrivalStationName));
      this.state.tripsData.map((station) => arrivalId.push(station.ArrivalStationId));
      this.state.tripsData.map((station) => departureName.push(station.DepartureStationName));
      this.state.tripsData.map((station) => departureId.push(station.DepartureStationId));
      let uniqueArrName = [...new Set(arrivalName)];
      let uniqueArrId = [...new Set(arrivalId)];
      let uniqueDepName = [...new Set(departureName)];
      let uniqueDepId = [...new Set(departureId)];

      // console.log('uniqueArrName ON SET', uniqueArrName);
      // console.log('uniqueArrId ON SET', uniqueArrId);
      // console.log('uniqueDepName ON SET', uniqueDepName);
      // console.log('uniqueDepId ON SET', uniqueDepId);

      const joinAnDn = [];
      const joinAiDi = [];

      for (let index = 0; index < uniqueArrName.length; index++) {
        joinAnDn.push(uniqueArrName[index]);
        joinAnDn.push(uniqueDepName[index]);
        joinAiDi.push(uniqueArrId[index]);
        joinAiDi.push(uniqueDepId[index]);
      }

      // console.log('joinAnDn ON PUSH', joinAnDn);
      // console.log('joinAiDi ON PUSH', joinAiDi);

      const uniqueNames = [...new Set(joinAnDn)];
      const uniqueIds = [...new Set(joinAiDi)];

      // console.log('uniqueNames ON SET', uniqueNames);
      // console.log('uniqueIds ON SET', uniqueIds);

      const uniqueSuggestions = [];

      for (let index = 0; index < uniqueNames.length; index++) {
        uniqueSuggestions.push([uniqueNames[index], uniqueIds[index]]);
      }

      this.setState(
        {
          uniqueFavs: uniqueSuggestions,
        },
        () => {
          this.dynamicTextSize();
        }
      );
    }
  };

  handleInputFocus = () => {
    this.setState({
      display: "block",
    });
  };

  handleInputBlur = () => {
    setTimeout(() => {
      this.setState({
        display: "hide",
      });
    }, 500);
  };

  dialogHandleOpen = () => {
    this.togglePopup();
  };
  //Handle click on one of the searched Cities
  handleClickEvent = (e) => {
    let value = e.currentTarget.getAttribute("value");
    let code = e.currentTarget.getAttribute("code");
    value = this.toUpperCaseFn(value);
    this.setState({
      selectedName: value,
      searchField: value,
      stations: null,
      code: code,
    });
  };
  //Check whether the typed station exist in the db
  checkStationExistence = () => {
    this.setState({ unknownStation: true });
    this.state.stations.forEach((station) => {
      if (
        station.Name.toLowerCase().replace(/\s+/g, "").trim() === this.state.searchField.toLowerCase().replace(/\s+/g, "").trim()
      ) {
        this.setState({ unknownStation: false });
        return;
      }
    });
  };
  render() {
    //console.log('PROPS: ', this.props);
    //console.log('STATE: ', this.state);
    return (
      <React.Fragment>
        <CssBaseline />
        <Popup display={this.state.popupDisplay} content={this.state.popup} hidePopup={this.togglePopup} />
        <div className='_container'>
          {this.props.state.screenWidth > 620 && (
            <Container maxWidth={false} disableGutters className='infoContainer'>
              {window.location.pathname.includes("/fr") ? (
                <Typography variant='h5' className='firstTimeDialogContent'>
                  Veuillez sélectionner votre arrêt de destination.
                </Typography>
              ) : window.location.pathname.includes("/it") ? (
                <Typography variant='h5' className='firstTimeDialogContent'>
                  Selezioni la sua fermata di destinazione.
                </Typography>
              ) : window.location.pathname.includes("/en") ? (
                <Typography variant='h5' className='firstTimeDialogContent'>
                  Please state the final destination of your journey.
                </Typography>
              ) : (
                <Typography variant='h5' className='firstTimeDialogContent'>
                  Bitte wählen Sie Ihre Ziel-Haltestelle.
                </Typography>
              )}
            </Container>
          )}
          <Container maxWidth='false' className='StartortScreen' disableGutters='true'>
            <Container maxWidth='false' disableGutters>
              <TextField
                onFocus={this.handleInputFocus}
                onBlur={this.handleInputBlur}
                value={this.state.searchField}
                name='searchField'
                autoComplete='off'
                id='von'
                label={
                  window.location.pathname.includes("/fr")
                    ? "À / Destination"
                    : window.location.pathname.includes("/it")
                    ? "A / Destinazione:"
                    : window.location.pathname.includes("/en")
                    ? "To / final destination:"
                    : "Nach / Ziel:"
                }
                fullWidth
                className='searchField'
                onChange={this.searchHandler}
              />
              <ul id='list' className='searchResults'>
                {!this.state.selectedName && this.state.searchField?.length > 2 && this.state.unknownStation ? (
                  <li
                    value={this.state.searchField}
                    code={-1}
                    onClick={(e) => this.handleClickEvent(e)}
                    // onClick={() =>
                    //   this.props.handleEndPoint(this.state.searchField, -1)
                    // }
                  >
                    {this.state.searchField}
                  </li>
                ) : null}

                {this.state.searchField?.length > 2 &&
                  this.state.selectedName === null &&
                  this.state.stations?.slice(0, 10).map((stations, index) => (
                    <li
                      key={index}
                      value={stations.Name}
                      code={stations.Code}
                      onClick={(e) => this.handleClickEvent(e)}
                      // onClick={() =>
                      //   this.props.handleEndPoint(stations.Name, stations.Code)
                      // }
                    >
                      {stations.Name}
                    </li>
                  ))}
              </ul>
              <Typography variant='body2' className='favouriteCopy'>
                {window.location.pathname.includes("/fr")
                  ? "Propositions / Favoris"
                  : window.location.pathname.includes("/it")
                  ? "Proposte / Preferiti"
                  : window.location.pathname.includes("/en")
                  ? "Suggestions / Favorites:"
                  : "Vorschläge / Favoriten"}
              </Typography>
              <div className='connSuggestionsButton'>
                {this.state.uniqueFavs === null ? null : this.state.uniqueFavs.length > 0 ? (
                  this.state.uniqueFavs
                    .slice(0, 8)
                    .reverse()
                    .map((station, index) => {
                      if (station[0]) {
                        return (
                          <Button
                            key={index}
                            onClick={() => this.props.handleEndPoint(station[0], station[1])}
                            variant='contained'
                            className='stationButton'
                            disableElevation
                            size='large'
                          >
                            <span id={"arrival" + station[1]}>{station[0]}</span>
                          </Button>
                        );
                      }
                      return "";
                    })
                ) : (
                  <Skeleton animation='wave' height={42} className='stationSkeleton' />
                )}
              </div>
            </Container>
            {this.props.state.screenWidth < 620 && (
              <InfoIcon onClick={this.dialogHandleOpen} className='helpModalButton zielortInfo' />
            )}
          </Container>
        </div>
        <BottomNav
          handleBtn={this.state.selectedName && this.props.handleEndPoint}
          btnParam={{ name: this.state.selectedName, code: this.state.code }}
          btnText={
            window.location.pathname.includes("/fr")
              ? "Continuer"
              : window.location.pathname.includes("/it")
              ? "Avanti"
              : window.location.pathname.includes("/en")
              ? "Continue"
              : "Weiter"
          }
          backBtnState={this.props.state.back}
          backButtonHandler={this.props.backButtonHandler}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(Main);
