import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import querystring from "querystring";
import Card from "@material-ui/core/Card";
import Backdrop from "@material-ui/core/Backdrop";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./styles.css";
class Main extends Component {
  constructor() {
    super();
    this.state = {
      dialogError: "",
      fade: "out",
      emailfield: "Hide",
      emailButton: "Hide",
      loginField: "Show",
      loginButton: "Show",
      emailNotifications: true,
      pushNotifications: false,
      loading: true,
      error: false,
      language: "de",
      count: 0,
    };
    this.activated = React.createRef();
    this.activated.current = false;
  }

  componentDidMount() {
    let params = new URLSearchParams(this.props.location.search);
    let paramsObj = {};
    params.forEach(function (value, key) {
      paramsObj[key] = value;
    });
    //Check if user already exists
    this.checkUser(paramsObj);
  }

  checkUser = (paramsObj) => {
    if (this.checkQuery(paramsObj)) {
      this.handleSubmitFromUrl(paramsObj);
    }
  };

  //Check for errors in query string
  checkQuery = (obj) => {
    if (obj.TIC && obj.pseudonym && obj.lang && obj.date) {
      return true;
    }
    this.setState({
      dialogError: "Error in the query string",
      fade: "in",
      loading: false,
      error: true,
    });
    setTimeout(() => {
      this.setState({ fade: "out" });
    }, 2500);
    return false;
  };

  //Check if user is locked
  checkLockSource = (value, language) => {
    if (value) {
      localStorage.clear();
      this.props.history.push(language + "?locked");
      window.location.pathname === "/fr"
        ? this.setState({
            dialogError: "Le questionnaire a déjà été finalisé, il n'est plus possible de le modifier.",
            fade: "in",
            loading: false,
          })
        : window.location.pathname === "/it"
        ? this.setState({
            dialogError: "L'inchiesta è già stata conclusa. Non sono più consentite modifiche.",
            fade: "in",
            loading: false,
          })
        : window.location.pathname === "/en"
        ? this.setState({
            dialogError: "You have already completed the survey. Alterations are therefore no longer possible.",
            fade: "in",
            loading: false,
          })
        : this.setState({
            dialogError: "Sie haben die Umfrage bereits abgeschlossen. Änderungen sind daher nicht mehr möglich.",
            fade: "in",
            loading: false,
          });
      setTimeout(() => {
        this.setState({ fade: "out" });
      }, 2500);
    } else {
      this.props.history.push(language + "/home");
    }
  };
  hideErrorDialog = () => {
    setTimeout(() => {
      this.setState({ fade: "out" });
    }, 4000);
  };
  registerHandler = (params) => {
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/authentication/activate`;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", window.localStorage.AccessToken);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("Email", "/");
    urlencoded.append("EmailRepeat", "/");
    urlencoded.append("EnableDailyNotifications", false);
    urlencoded.append("EnablePushNotificationReminder", false);
    urlencoded.append("Pseudonym", params.pseudonym);
    urlencoded.append("TIC", params.TIC);
    urlencoded.append("JourneyDate", params.date);
    urlencoded.append("Language", params.lang);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch(proxyUrl + targetUrl, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.handleSubmitFromUrl(params);
      })
      .catch((error) => console.log("error", error));
  };

  onChangeHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleUser = (params) => {
    var proxyUrl = process.env.REACT_APP_PROXY_URL,
      targetUrl = `${process.env.REACT_APP_BASE_URL}/api/authentication/userInfo`;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", window.localStorage.AccessToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(proxyUrl + targetUrl, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({
          userProfile: result.Preload,
          resultsData: result,
        });
        if (result.IsRegistered === false) {
          this.activated.current = true;
          this.registerHandler(params);
        } else {
          let language =
            result.Preload.Language === 1 ? "/de" : result.Preload.Language === 2 ? "/fr" : result.Preload.Language === 3 ? "/it" : result.Preload.Language === 4 ? "/en" : "/de";

          //Check if the user has closed his survery, if not redirect /home
          this.checkLockSource(result.Preload.LockSource, language);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        this.setState({ dialogError: "There was an error loading the user", fade: "in", loading: false, error: true });
        this.hideErrorDialog();
      });
  };

  registerEmailHandler = () => {
    this.setState({
      emailfield: "Show",
      emailButton: "Show",
      loginField: "Hide",
      loginButton: "Hide",
    });
  };

  customHandleSubmit = (param) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/token`,
        querystring.stringify({
          TNC: param,
          grant_type: "password",
        })
      )

      .then((response) => {
        window.localStorage.setItem("AccessToken", "Bearer " + response.data.access_token);
        this.handleUser();
        // this.props.history.push(window.location.pathname + '/welcome')
      })
      .catch((error) => {
        console.log("There is an error: ", error.message);
        if (error.message === "Request failed with status code 400") {
          window.location.pathname === "/fr"
            ? this.setState({
                dialogError: "Ces données ne sont pas valables, veuillez vérifier le NIP et/ou le nom, puis réessayer.",
                fade: "in",
              })
            : window.location.pathname === "/it"
            ? this.setState({
                dialogError: "Questi dati non sono validi; verifichi il PIN e/o il nome e riprovi.",
                fade: "in",
              })
            : window.location.pathname === "/en"
            ? this.setState({
                dialogError: "The participation code is invalid. Please check your input and try again.",
                fade: "in",
              })
            : this.setState({
                dialogError: "Der Teilnahmecode ist ungültig. Bitte überprüfen Sie Ihre Eingabe und versuchen Sie es erneut.",
                fade: "in",
              });
        } else {
          this.setState({
            dialogError: "" + error.message,
            fade: "in",
          });
        }
        setTimeout(() => {
          this.setState({ fade: "out" });
        }, 2500);
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/token`,
        querystring.stringify({
          TNC: event.target[0].value,
          grant_type: "password",
        })
      )

      .then((response) => {
        window.localStorage.setItem("AccessToken", "Bearer " + response.data.access_token);
        this.handleUser();
        // this.props.history.push(window.location.pathname + '/welcome')
      })
      .catch((error) => {
        console.log("There is an error: ", error.message);
        if (error.message === "Request failed with status code 400") {
          window.location.pathname === "/fr"
            ? this.setState({
                dialogError: "Ces données ne sont pas valables, veuillez vérifier le NIP et/ou le nom, puis réessayer.",
                fade: "in",
              })
            : window.location.pathname === "/it"
            ? this.setState({
                dialogError: "Questi dati non sono validi; verifichi il PIN e/o il nome e riprovi.",
                fade: "in",
              })
            : window.location.pathname === "/en"
            ? this.setState({
                dialogError: "The participation code is invalid. Please check your input and try again.",
                fade: "in",
              })
            : this.setState({
                dialogError: "Der Teilnahmecode ist ungültig. Bitte überprüfen Sie Ihre Eingabe und versuchen Sie es erneut. ",
                fade: "in",
              });
        } else {
          this.setState({
            dialogError: "" + error.message,
            fade: "in",
          });
        }
        setTimeout(() => {
          this.setState({ fade: "out" });
        }, 2500);
      });
  };
  handleSubmitFromUrl = (params) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/token`,
        querystring.stringify({
          TNC: `${params.pseudonym}${params.TIC}`,
          grant_type: "password",
        })
      )
      .then((response) => {
        window.localStorage.setItem("AccessToken", "Bearer " + response.data.access_token);
        if (this.activated.current) {
          setTimeout(
            function () {
              this.handleUser();
            }.bind(this),
            2000
          );
        } else {
          this.handleUser(params);
        }
      })
      .catch((error) => {
        console.log("There is an error: ", error.message);
        if (error.response.status === 400) {
          params.lang === "fr"
            ? this.setState({
                dialogError: "Ces données ne sont pas valables, veuillez vérifier le NIP et/ou le nom, puis réessayer.",
                fade: "in",
                loading: false,
                error: true,
              })
            : params.lang === "it"
            ? this.setState({
                dialogError: "Questi dati non sono validi; verifichi il PIN e/o il nome e riprovi.",
                fade: "in",
                loading: false,
                error: true,
              })
            : params.lang === "en"
            ? this.setState({
                dialogError: "The participation code is invalid. Please check your input and try again.",
                fade: "in",
                loading: false,
                error: true,
              })
            : this.setState({
                dialogError: "Der Teilnahmecode ist ungültig. Bitte überprüfen Sie Ihre Eingabe und versuchen Sie es erneut. ",
                fade: "in",
                loading: false,
                error: true,
              });
        } else {
          this.setState({
            dialogError: "" + error.message,
            fade: "in",
          });
        }
        setTimeout(() => {
          this.setState({ fade: "out" });
        }, 4000);
      });
  };
  render() {
    return (
      <>
        <Card raised={true} className={"errorDialog fade-" + this.state.fade}>
          <div className='errorContentLogin'>
            <p>{this.state.dialogError}</p>
          </div>
        </Card>
        <div style={{ minHeight: "500px", display: "flex", alignItems: "center", justifyContent: "center" }} className='_loadingScreen loginScreen'>
          <Backdrop open={this.state.loading} style={{ color: "white", textAlign: "center", zIndex: "10" }}>
            {window.location.pathname.includes("/fr") ? (
              <Typography variant='subtitle2'>
                <CircularProgress color='primary' />
                <br />
                Nous traitons vos données.
                <br />
                Veuillez patienter un instant.
              </Typography>
            ) : window.location.pathname.includes("/it") ? (
              <Typography variant='subtitle2'>
                <CircularProgress color='primary' />
                <br />
                Elaboriamo i vostri dati.
                <br />
                Vi prego di avere pazienza.
              </Typography>
            ) : window.location.pathname.includes("/en") ? (
              <Typography variant='subtitle2'>
                <CircularProgress color='primary' />
                <br />
                We are processing your data.
                <br />
                Please wait a moment.
              </Typography>
            ) : (
              <Typography variant='subtitle2'>
                <CircularProgress color='primary' />
                <br />
                Wir verarbeiten Ihre Daten.
                <br />
                Bitte haben Sie einen Moment Geduld.
              </Typography>
            )}
          </Backdrop>
          {/* {this.state.loading && <h2>Checking user...</h2>}
          {this.state.error && <h2>Error checking user...</h2>} */}
        </div>
      </>
    );
  }
}

export default withRouter(Main);
